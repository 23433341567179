<template>
  <div class="agency">
    <navbar/>
    <div class="organization" style="height:567px;">
      <img
        src="../../assets/img/organizationbg.png"
        style="width: 100%; height: 567px;"
      />
    </div>
    <div class="organiza_text">
      <div style="margin-top: 100px">
        <img
          src="../../assets/img/organizationlogo.png"
          alt=""
          style="width: 200px"
        />
      </div>
      <div class="text">
        <p style="font-size:17px;font-weight:600;">博愛堂服務中心(非牟利機構)</p>
        <p class="p2">香港慈善牌照號碼：REF.CP/LIC/SO/19/53510</p>
        <p style="padding-top: 30px;font-size:17px;font-weight:600;">本會簡介</p>
        <p class="p2">本會由一班熱心人士組成，希望凝聚有愛心及關懷弱勢的朋友，一起推</p>
        <p class="p2">展各項慈善活動，於各區提供更多支援，共建和諧社會</p>
      </div>
      <div class="action">
        <div class="bg">
          <p>救弱扶老</p>
          <p>幫助弱勢社群、老人、體現人間暖流</p>
        </div>
        <div class="bg">
          <p>保衛毛孩</p>
          <p>拯救被遺棄動物，給予愛心及避風港</p>
        </div>
        <div class="bg">
          <p>贈己施愛</p>
          <p>協助低收入家庭、基層或身患重病人士渡過難關延續生命</p>
        </div>
      </div>
    </div>
    <div><copyright /></div>
  </div>
</template>

<script>
import api from '@/api';
export default {
};
</script>


<style>
.p2{
  font-size:15px;
  margin-top:10px;
}
.agency {
  margin-top:80px;
  font-size: 10px;
}
.action {
  margin-top: 50px;
  width: 100%;
  margin-bottom: 50px;
}
.organiza_text{
  text-align:center;
}
.bg {
  width: 400px;
  height: 75px;
  margin: 0 auto;
  background-color: #fff1f4;
  margin-top: 10px;
  border-radius: 10px;
}
.bg > p {
  line-height: 20px;
  text-align: center;
  padding-top: 10px;
}
</style>